@tailwind base;
@tailwind components;
@tailwind utilities;

*{

    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: "DynaPuff", serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
}


html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}