.slick-initialized .slick-slide {
    padding: 10px !important;
}

.before,
.after {
    position: absolute;
    color: orange;
    font-size: 30px;
}

.before {

    top: 0px;
    left: 0px;
    transform: translate(-50%, -50%);

}
.after {

    bottom: 0px;
    right: 0px;
    transform: translate(50%, 50%);

}