/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*{

    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}